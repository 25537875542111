import { on } from 'delegated-events';

on('click', '[data-share]', async (event) => {
  const { currentTarget: $target } = event;

  event.preventDefault();

  if (!('share' in window.navigator)) {
    await import(/* webpackChunkName: "share-api-polyfill" */ 'share-api-polyfill');
  }

  const { shareUrl = window.location.href, shareTitle = document.title, shareText = '' } = $target.dataset;

  try {
    await window.navigator.share(
      {
        title: shareTitle,
        text: shareText,
        url: shareUrl,
      },
      { language: 'de' },
    );
  } catch (e) {
    // Ignore
  }
});
